import { Http } from '~/composables/useApiFetch'

export default new class news extends Http {

  // 获取订单
  public stripePayEvent(params:object) {
    return this.post('/payment/stripe-events', params, true)
  }

  // 获取订单
  public getGoodsOrder(params:object) {
    return this.get('/front/customized/getGoodsOrderLIstByConsumerId', params, true)
  }
  public getGoodsOrderNums() {
    return this.get('/front/customized/getGoodsOrderNumsByConsumerId', {}, true)
  }
  
  // 获取订单详情
  public getGoodsOrderDetail(id: string) {
    return this.get('/jm-goods-order/'+ id, {}, true)
  }
  public getGoodsOrderDetailNew(id: string) {
    return this.get('/front/jm-goods-order/'+ id, {}, true)
  }
	// 删除订单
	public delGoodsOrder(id: string) {
	  return this.delete('/jm-goods-order/'+ id, {}, true)
	}
	// 修改订单
	public putGoodsOrder(data: object) {
	  return this.put('/front/jm-goods-order', data, true)
	}
  // 提交补充资料
  public putGoodsOrderReplenish(data: any) {
	  return this.put('/front/jm-goods-order/replenish', data, true)
  }
  // 取消订单
  public cancelOrder(data: any) {
    return this.post('/front/jm-goods-order/cancel', data, true)
  }
	
  // 获取物流
	public getLogistics(id: string, orderId:string) {
	  return this.get(`/logistics/getByCode/${encodeURIComponent(id)}/${orderId}`, {}, true)
	}
	// 创建订单
	public createOrder(data: object, param: object) {
	  return this.post('/front/customized/createGoodsOrderByShoppingCart',data, true,param)
	}
	// 购物车下单
	public shoppingCartOrder() {
		return this.get('/front/customized/shoppingCartOrder',{}, true)
	  }

	// 获取商品信息
	public getGoodsByOrder(data: object) {
	  return this.get('/front/customized/getGoodsByOrder', data, true)
	}

  //检查广告推送
  public checkAdsPush(id: string) {
    return this.get('/jm-goods-order/checkoutPush/'+id, {}, true)
  }

	public getRefundableGoodsOnly(data: object) {
		return this.get('/front/customized/getRefundGoodsByOrder', data, true)
	  }
	
	public pay(data:object){
		return this.post('/payment/pay',data,true)
	}

	public paypal(data:object){
		return this.post('/paypal/createOrder ',data,true)
	}

	public sezzle(data:object){
		return this.post('/sezzle/createCheckout ',data,true)
	}
	
	public afterPay(data:object){
		return this.post('/afterPay/createCheckout',data,true)
	}
	
	// 获取运费
	public getFreight(data: object) {
	  return this.get('/jm-freight-settings', data, true)
	}

	public payEvent(data: object, param: object){
		return this.post('/payment/pay/event',data,true,param)
		
	}

	public getOrderEvent(data: object){
		return this.get('/jm-order-event', data, true)
	}

	// 获取订单评论详情
	public getEvaluationDetail(id: string) {
		return this.get('/jm-appraise/'+ id, {}, true)
	  }
	// 获取订单评论详情新
	public getEvaluationDetailNew(data: object) {
		return this.get('/front/jm-appraise', data, true)
	}
	//   再次购买
	  public buyAgain(id: string){
		return this.get('/front/customized/buyAgain/'+id, {}, true)
	}


	public paymentConfig(data:object){
		return this.get('/payment/client-config',data,true)
	}
	public paymentStripeInline(data:object){
		return this.post('/payment/stripePay',data,true)
	}
  /**
   * 售后 stripePay 支付
   * @param id 售后单编号
   */
  public paymentAfterSaleStripeInline(id: string | number) {
    return this.post(`/payment/stripePay/after-sales/${id}`, {}, true);
  }

  public orderPayDiffUrl(id: string | number,payWay:string) {
    return this.get(`/front/jm-goods-order/${id}/pay-diff-url/${payWay}`, {}, true);
  }

  public paymentStripeInlineDiff(id: string | number){
	return this.post(`/payment/stripePay/order/${id}`,{},true)
}

	public orderLogistics(id: string | number) {
    return this.get(`/logistics/getByOrderId/${id} `, {}, true);
  } 


  public zeropay(data:object){

	return this.post('/zeropay/createOrder',data,true)
  }


}